import React from "react";
import SEO from "../../components/common/seo";
import LayoutThird from "../../components/common/layout-third";
import IdentityNav from "../../components/common/identity-nav";
import ModalContactSales from "../../components/modals/modal-contact-sales";
import {Link} from "@reach/router";
import ModalWhitePaper from "../../components/modals/modal-white-paper";
import Partners from "../../components/common/partners";
import Interested from "../../components/identity/interested";
import ContactBox from "../../components/common/contact-box";
import {graphql, useStaticQuery} from "gatsby";
import ReactMarkdown from "react-markdown";
import {customElements} from "../../utils/global-functions";
import rehypeRaw from "rehype-raw";

const IndexPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiIdentityHome.edges[0].node;
    const menu = data.menu.frontmatter[lang];
    const icons = ['fe-monitor', 'fe-crosshair', 'fe-pie-chart']
    const steps = ['fe-credit-card', 'fe-aperture', 'fe-check-circle']
    const customElementTwo = (pClassName = '', spanClassName = '') => {
        return {
            p: ({node, ...props}) => <p className={pClassName} {...props} />,
            strong: ({node, ...props}) => <span className={spanClassName} {...props} />,
        }
    };

    return (
        <LayoutThird pageContext={pageContext} cookies={data.cookies} favicon="favicon-ide.png">
            <SEO lang={lang} title={page.seo.title} image={page.seo.image.localFile.publicURL} description={page.seo.meta_description}/>

            <IdentityNav pageContext={pageContext}/>

            <div id="product-top" className="content">
                <header className="masthead">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-xl-5 text-left" data-aos="fade-right">
                                <h1 className="spacing mb-4 display-4">
                                    <img src={require("../../img/identity-white.png").default} width="42" className="mobile mr-3 badge-signature" alt="Identity"/>
                                    {page.title}
                                </h1>

                                <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} components={customElements('spacing mb-4')}/>

                                <p>
                                    <a href="#more-info" className="btn btn-primary spacing btn-sm mr-4 spacing smooth-scroll">
                                        {lang === 'es' ? 'Conocer más' : 'Know more'}
                                    </a>
                                    <a href="#" data-toggle="modal" data-target="#modal-contact-sales" className="ventas spacing block-btn ml-1">
                                        {lang === 'es' ? 'Contactar con ventas' : 'Contact sales'}
                                    </a>
                                </p>
                            </div>

                            <div className="col-xl-7 text-center" data-aos="fade-left">
                                <div className="img-skewed img-skewed-left">
                                    <img src={require("../../img/admin-identity-bg.png").default} className="rounded img-fluid mw-md-130 img-skewed-item header-presentation"
                                         alt="Identity"
                                         style={{position: 'relative', top: '-23px'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>

            <section id="more-info" className="py-11 functions-sig bg-grey-1" style={{position: 'relative', zIndex: '90'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <a href="#more-info" className="btn btn-red btn-rounded-circle shadow smooth-scroll" style={{marginTop: '-220px'}}>
                                <i className="fe fe-arrow-down"/>
                            </a>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-xl-12" data-aos="fade-down" data-aos-delay="0">
                            <hr className="black-line"/>

                            <h3>
                                <ReactMarkdown children={page.solution_description} rehypePlugins={[rehypeRaw]}/>
                            </h3>
                        </div>
                    </div>

                    <div className="row mb-1">
                        <div className="col-xl-3 check-list mt-9">
                            {page.solution_items.map((item, i) => {
                                return <div data-aos="fade-down" data-aos-delay={(i + 1) * 100}>
                                    <h3 className="mt-8 spacing font-weight-bold"><i className={`fe ${icons[i]} icon-land text-tecalis`}/> {item.title}</h3>
                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('spacing font-size-md')}/>
                                </div>
                            })}
                        </div>

                        <div className="col-xl-1"/>

                        <div className="col-xl-8" data-aos="fade-left" data-aos-delay="300">
                            <div className="position-relative pt-7 mb-md-9 mt-9 border rounded-xl overflow-hidden">
                                <div className="position-absolute top-0 right-0 bottom-0 left-0 bg-gradient-multicolor1">
                                    <div className="d-flex" style={{paddingTop: '15px', paddingLeft: '15px'}}>
                                        <span className="bg-danger rounded-circle" style={{width: '10px', height: '10px'}}/>
                                        <span className="bg-warning rounded-circle ml-2" style={{width: '10px', height: '10px'}}/>
                                        <span className="bg-success rounded-circle ml-2" style={{width: '10px', height: '10px'}}/>
                                    </div>
                                </div>
                                <img className="position-relative img-fluid shadow-lg" src={require("../../img/admin-identity1.png").default} alt="..."/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-8 py-md-11 bg-white border-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <span className="badge badge-pill badge-primary-soft mb-3" data-aos="fade-up">
                                <span className="h6 text-uppercase font-weight-bold">{page.how_works_tag}</span>
                            </span>

                            <br/><br/><br/>

                            <h2 className="display-4 font-weight-bold spacing" data-aos="fade-up">
                                {page.how_works_title}
                            </h2>


                            <hr className="hr-sm my-6 my-md-8 border-gray-300"/>
                        </div>

                        <div className="col-xl-12 text-center">
                            <h2 align="left" className="font-weight-bold spacing" data-aos="fade-up">
                                <ReactMarkdown children={page.how_works_description} rehypePlugins={[rehypeRaw]} components={customElements(customElementTwo('', 'text-primary'))}/>
                            </h2>
                        </div>
                    </div>

                    <div className="row mt-7">
                        {page.how_works_items.map((item, i) => {
                            return <div className="col-xl-4" key={i}>
                                <div className="device device-iphonex" data-aos="fade-right" data-aos-delay="0">
                                    <img src={item.icon.localFile.publicURL} className="device-screen" alt={item.title}/>
                                    <img src={require("../../img/iphonex.svg").default} style={{marginLeft: '-0.45px', marginTop: '0.35px'}} className="img-fluid" alt="..."/>
                                </div>

                                <h3 align="center" className="display-5 font-weight-bold spacing" data-aos="fade-right" data-aos-delay="0">
                                    <i className={`fe ${steps[i]} icon-land1 text-tecalis mr-2`}/> {item.title}
                                </h3>

                                <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('spacing font-size-md mt-5 ml-5 mr-5 text-center')}/>
                            </div>
                        })}
                    </div>
                </div>
            </section>

            <section className="py-8 mt-10" style={{position: 'relative', zIndex: '91'}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-6" data-aos="fade-up">

                            <h3 align="left" className="font-weight-bold spacing">
                                <ReactMarkdown children={page.legality_title} rehypePlugins={[rehypeRaw]}/>
                            </h3>

                            <br/>

                            <ReactMarkdown children={page.legality_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg mb-6 mb-md-6 spacing')}/>

                            <p align="left">
                                <Link to={menu.legality.url} className="text-tecalis font-weight-bold spacing">
                                    {page.legality_button} <i className="fe fe-arrow-right ml-3"/>
                                </Link>
                            </p>

                            <div className="row mt-5 mb-5">
                                <div className="col-sm-4">
                                    <div className="card" data-aos="fade-down">
                                        <div className="card-body my-auto">
                                            <h2 align="center" className="mt-auto spacing font-weight-bold pt-2">
                                                <img className="img-fluid logo-filter" src={require("../../img/gdpr.png").default} width="80px" alt="..."
                                                     style={{borderRadius: '8px'}}/>
                                            </h2>

                                            <h2 align="center" className="mb-0 font-size-md spacing font-weight-bold">
                                                GDPR
                                            </h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card" data-aos="fade-down">
                                        <div className="card-body my-auto">
                                            <h2 align="center" className="mt-auto spacing font-weight-bold pt-2">
                                                <img className="img-fluid logo-filter" src={require("../../img/eidas-icon.png").default} width="80px" alt="..."
                                                     style={{borderRadius: '8px'}}/>
                                            </h2>

                                            <h2 align="center" className="mb-0 font-size-md spacing font-weight-bold">
                                                eIDAS
                                            </h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card" data-aos="fade-down">
                                        <div className="card-body my-auto">
                                            <h2 align="center" className="mt-auto spacing font-weight-bold pt-2">
                                                <img className="img-fluid logo-filter" src={require("../../img/aml.png").default} width="80px" alt="..."
                                                     style={{borderRadius: '8px'}}/>
                                            </h2>

                                            <h2 align="center" className="mb-0 ml-2 font-size-md spacing font-weight-bold">
                                                AML
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
                            <div className="device-combo device-combo-iphonex-iphonex mb-6 mb-md-0">
                                <div className="device device-iphonex" data-aos="fade-left">
                                    <img src={require("../../img/identity-mock-m5.png").default} className="device-screen" alt="..."/>
                                    <img src={require("../../img/iphonex.svg").default} style={{marginLeft: '-0.45px', marginTop: '0.35px'}} className="img-fluid" alt="..."/>
                                </div>

                                <div className="device device-iphonex" data-aos="fade-left" data-aos-delay="150">
                                    <img src={require("../../img/identity-mock-m2.png").default} className="device-screen" alt="..."/>
                                    <img src={require("../../img/iphonex.svg").default} className="img-fluid" alt="..."/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-8 py-md-11 bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <h2 className="display-4 font-weight-bold spacing">
                                {page.strategy_title}
                            </h2>

                            <hr className="hr-sm my-6 my-md-8 border-gray-300"/>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-12 col-lg-12 text-center" data-aos="fade-down">
                                <ReactMarkdown children={page.strategy_description}
                                               rehypePlugins={[rehypeRaw]}
                                               components={customElements('font-size-lg text-gray-700 mb-6 mb-md-6 spacing text-left', 'default-link')}/>

                                <br/><br/>
                            </div>
                        </div>

                        <div className="row">
                            {page.strategy_items.map((item, i) => {
                                return <div className="col-12 col-md-4 text-center" data-aos="fade-up" key={i}>
                                    <img src={item.icon.localFile.publicURL} width="100" className="mobile mr-3" alt={item.title} />

                                    <br/><br/>

                                    <h2 className="font-weight-bold spacing">
                                        {item.title}
                                    </h2>

                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing text-left')}/>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </section>

            <Interested pageContext={pageContext}/>

            <Partners pageContext={pageContext}/>

            <ContactBox pageContext={pageContext} product="Identity"/>

            <ModalWhitePaper pageContext={pageContext}/>

            <ModalContactSales pageContext={pageContext} product="Identity"/>
        </LayoutThird>
    )
}

export default IndexPage

export const indexPageQuery = graphql
    `query($lang: String)
    {
        allStrapiIdentityHome ( filter: { locale: { eq: $lang } } )
        {
            edges {
                node {
                    id
                    seo {
                        title
                        meta_description
                        image {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    title
                    description
                    solution_description
                    solution_items {
                        order
                        title
                        description
                    } 
                    how_works_tag
                    how_works_title
                    how_works_description
                    how_works_items {
                        order
                        title
                        icon {
                            localFile {
                                publicURL
                            }
                        }
                        description
                    }
                    legality_title
                    legality_description
                    legality_button
                    strategy_title
                    strategy_description
                    strategy_items {
                        order
                        title
                        icon {
                            localFile {
                                publicURL
                            }
                        }
                        description
                    }
                }
            }
        }
        menu: markdownRemark (
            frontmatter: {
                name: { eq: "menu-identity" }
            }
        ) {
            frontmatter {
                en {
                    legality {
                        url
                        name
                    }
                }
                es{
                    legality {
                        url
                        name
                    }
                }
            }
        }
        cookies: markdownRemark(
            frontmatter
    :
        {
            lang: {
                eq: $lang
            }
            name: {
                eq: "cookies"
            }
        }
    )
        {
            frontmatter
            {
                cookiesStart
                cookiesEnd
                linkText
            }
        }
    }
`;
